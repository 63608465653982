<template>
    <div class="">
        <el-card body-style="padding-bottom: 0;" style="margin-top: -5px;">
            <div class="title">文件审查目录设置</div>
            <div class="line"></div>
            <el-row style="margin-top: -10px;">
                <el-form>
                    <el-form-item>
                        <el-row>
                            <el-col :span="pageSpan">
                                <el-button v-preventReClick class="btn" type="primary" size="small"
                                    @click="addOrUpdateHandle()">新增</el-button>
                            </el-col>
                        </el-row>
                    </el-form-item>
                </el-form>
            </el-row>
        </el-card>
        <div>
            <el-card style="margin-top: -25px; height: 580px;" body-style="height:540px; padding: 0;">
                <el-row style="height: 100%; margin-bottom: 10px;">
                    <el-col :span="24" style="height: 100%;">
                        <div class="box">
                            <el-table height="520" :row-class-name="tableRowClassName" class="dataListTable" border
                                :data="dataList" header-align="center" style="width: 100%; margin-bottom: 20px;"
                                :cell-style="{ padding: '0px' }" header-cell-class-name="stepPointTHeader">
                                <el-table-column prop="kindName" label="一级目录" header-align="center" align="center">
                                    <template slot-scope="scope">
                                        <span v-if="scope.row.categoryId == 1">{{ scope.row.kindName }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="kindName" label="二级目录" header-align="center" align="center">
                                    <template slot-scope="scope">
                                        <span v-if="scope.row.categoryId == 2">{{ scope.row.kindName }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="kindName" label="三级目录" header-align="center" align="center">
                                    <template slot-scope="scope">
                                        <span v-if="scope.row.categoryId == 3">{{ scope.row.kindName }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="kindName" label="系统保留" header-align="center" align="center">
                                    <template slot-scope="scope">
                                        <span v-if="scope.row.oaDirId != null"> 系统 </span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="" header-align="center" align="left">
                                    <template slot-scope="scope">
                                        <el-button v-preventReClick type="text" size="mini" v-if="scope.row.oaDirId == null"
                                            @click="addOrUpdateHandle(scope.row.recId)">修改</el-button>
                                        <el-button v-preventReClick type="text" size="mini" v-if="scope.row.oaDirId == null"
                                            @click="deleteHandle(scope.row.recId)">删除</el-button>
                                        <el-button v-preventReClick type="text" size="mini" v-if="scope.row.categoryId != 3"
                                            @click="addChildFileKindHandle(scope.row.recId)">添加子类型</el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                    </el-col>
                </el-row>
            </el-card>
        </div>
        <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList()"></add-or-update>
        <child-file-setting-add v-if="childFileSettingAddVisible" ref="childFileSettingAdd"
            @refreshDataList="getDataList()"></child-file-setting-add>
    </div>
</template>
<script>
import AddOrUpdate from './file-setting-add-or-update.vue'
import ChildFileSettingAdd from './child-file-setting-add.vue'
import $common from "@/utils/common.js"
export default {
    data() {
        return {
            showAdd: false,
            showFileKindSet: false,
            showFileDownload: false,
            showCheck: false,
            showEdit: false,
            showArchive: false,
            showDelete: false,
            pageSpan: 12,
            tabIndex: '',
            addOrUpdateVisible: false,
            childFileSettingAddVisible: false,
            recId: '',
            examineTime: [],
            dataList: [],
            tableSelVal: [],
            //审查文件类型
            documentTypeList: [],
            //当前页码
            pageIndex: 1,
            //当前记录数
            pageSize: 10,
            //总页数
            totalPage: 0,
            defaultProps: {
                children: 'childList',
                label: 'kindName'
            }
        };
    },
    components: {
        AddOrUpdate,
        ChildFileSettingAdd
    },
    mounted() {
        this.getDataList();
    },
    methods: {
        //获取数据
        getDataList() {
            this.$http({
                url: this.$store.state.httpUrl + "/business/filekindconfig/list",
                method: "get",
                params: {
                    workNo: $common.getItem("workNo"),
                    kindType: "01",
                    limit: this.pageSize,
                    page: this.pageIndex
                },
            }).then(({ data }) => {
                if (data && data.resultCode === 200) {
                    this.dataList = data.body;
                    this.totalPage = data.body.totalCount;
                }
            });
        },
        //删除
        deleteHandle(id) {
            this.recId = id ? id : 0;
            this.$confirm(`确定对选中的数据进行删除操作?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$http({
                    url: this.$store.state.httpUrl + `/business/filekindconfig/delete/${this.recId}`,
                    method: "POST"
                }).then(({ data }) => {
                    if (data && data.resultCode === 200) {
                        this.$message({
                            message: '操作成功',
                            type: 'success',
                            duration: 1500,
                            onClose: () => {
                                this.getDataList()
                            }
                        })
                    }
                });
            })
                .catch(() => { })
        },
        handleSelectionChange(val) {
            this.tableSelVal = val;
        },
        // 每页数
        sizeChangeHandle(val) {
            this.pageSize = val
            this.pageIndex = 1
            this.getDataList()
        },
        // 当前页
        currentChangeHandle(val) {
            this.pageIndex = val
            this.getDataList()
        },
        addOrUpdateHandle(id) {
            this.addOrUpdateVisible = true
            this.$nextTick(() => {
                this.$refs.addOrUpdate.init(id, "01")
            })
        },
        addChildFileKindHandle(id) {
            this.childFileSettingAddVisible = true
            this.$nextTick(() => {
                this.$refs.childFileSettingAdd.init(id, "01")
            })
        },
        selGetDataList() {
            this.pageIndex = 1;
            this.getDataList();
        },
        siteContentViewHeight() {
            var height = this.$store.state.common.documentClientHeight - 160;
            return { height: height + 'px' }
        },
        tableRowClassName({ row, rowIndex }) {
            return $common.tableRowClassName({ row, rowIndex });
        },
    }
};
</script>
<style lang="scss" scoped>
// 设置表头样式
::v-deep(.el-table th.el-table__cell) {
    // background-color: #d3e2f4 !important;
    font-size: 15px;
    padding: 2px 0;
}
</style>
